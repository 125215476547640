<!-- Start Marketing Main Banner -->
<div class="main-banner marketing-home" id="top">
    <div class="man">

        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                        <div class="marketing-image">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Generá leads calificados, <br> digitalizá tu catálogo <br>y llegá de forma más <br>simple a tus contactos.</h1>
                            <p class="bajada">
                                Potenciá tus negocios en WhatsApp: el canal donde ya están tus clientes.
                            </p>
                            <p>Simple y disponible 24x7 ¡Cero barreras de adopción!</p>
                            
                            <a href="#demo" class="btn btn-primary">Solicita una demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<a style="display: block;" href="https://wa.me/+5493412828012?text=Hola%20quiero%20digitalizar%20con%20Agromodo" target="_blank">
    <img class="wpp-button" src="assets/img/logo-wpp.webp" alt="image">
</a>
<!-- End Marketing Main Banner -->

<!-- <app-partner></app-partner> -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100" id="agromodo">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/foto1.webp" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h4>Por qué Agromodo</h4>
                    <h3>
                        Optimizá los procesos conversacionales de marketing, ventas, gestión de pedidos y atención al cliente.
                    </h3>
                    <p>
                        Comunicación fluída y unificada de punta a punta. Complementá y potenciá el proceso de venta presencial.
                    </p>
                    <a href="#modulos" class="btn btn-primary">Conoce los Módulos de Agromodo</a>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- End Overview Area -->

<!-- Start Features Area -->
<section class="features-area features-dos marketing-features ptb-100">
    <div class="features-inner-area featuro-inner-dos">
    
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">

                <h3 class="titulo">Plataforma Agromodo: <br>
                    Panel de gestión de campañas y métricas en tiempo real 
                    + Bot en WhatsApp disponible 24x7
                </h3>
                
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/foto2.webp" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-label"></i>
                            </div>
                            <h3>Marca blanca</h3>
                            <p>
                                (Customización con Look & feel de tu marca).Impulsa tu branding y posicionamiento.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-responsive"></i>
                            </div>
                            <h3>Set Up libre de instalaciones</h3>
                            <p>
                                Conversaciones automatizadas en Whatsapp. <br>Panel de gestión web para envios de campañas.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-addons"></i>
                            </div>
                            <h3>100% APIFICADO</h3>
                            <p>
                                Registro de conversaciones en base de datos. Capacidad de Procesamiento y métricas.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-bars"></i>
                            </div>
                            <h3>SAAS</h3>
                            <p>
                                Modelo comercial simple y transparente.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/sombra.png" alt="img"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
</section>  


<section class="features-area marketing-features ptb-100">
    <div class="features-inner-area">

        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 centrar">
                            <div class="single-holder-box">
                                <div class="centraVertical">
                                    <img src="assets/img/icono1.png" alt="image">
                                    <h3>Para Empresas fabricantes de insumos</h3>
                                </div>
                            </div>
                            
                            <div class="single-holder-box mt-30">
                                <div class="centraVertical">        
                                    <img src="assets/img/grupo2.png" alt="image">
                                    <h3>Agronomías y Distribuidores</h3>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6 centrar">
                            <div class="single-holder-box mt-50">
                                <div class="centraVertical">
                                    <img src="assets/img/grupo.png" alt="image">
                                    <h3>Acopios de cereales</h3>
                                </div>
                            </div>
                            
                            <div class="single-holder-box mt-30">
                                <div class="centraVertical">
                                    <img src="assets/img/icono3.png" alt="image">
                                    <h3>Fabricantes de Maquinarias</h3>
                                </div>
                        </div>

                            <div class="single-holder-box mt-30">
                                <div class="centraVertical">
                                    <img src="assets/img/icono4.png" alt="image">
                                    <h3>Concesionarios</h3>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            
                <div class="col-lg-5 col-md-12 paddingleft10">
                    <div class="features-holder-content">
                    <div class="section-title">
                        <p>
                            Con AGROMODO tenés visibilidad y seguimiento de la relacion de tu equipo comercial con los clientes.
                        </p>
                    </div>
                    <a href="#demo" class="btn btn-nuevo">Quiero saber más</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape12"><img src="assets/img/sombra.png" alt="img"></div>
    <!-- <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div> -->
    <!-- <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div> -->
</section>
<!-- End Features Area -->

<!-- Start App Funfacts Area -->
<section class="app-funfacts-area ptb-100" id="modulos">
    <div class="container">
        <h2>3 MÓDULOS PARA <br>RELACIONARTE CON TUS CLIENTES:</h2>
        <div class="row rowpeq">
            <div class="col-lg-4 col-md-4">
                <div class="single-funfact-box">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono6.png" alt="image">
                    </div>
                    <h3><span class="count">Marketing</span></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="single-funfact-box">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono7.png" alt="image">
                    </div>
                    <h3><span class="count">Pedidos</span></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="single-funfact-box">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono8.png" alt="image">
                    </div>
                    <h3><span class="count">At. Clientes</span></h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Funfacts Area -->

<!-- Start Services Area -->
<!-- <section class="services-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Search Strategy</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Onsite SEO</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Keyword Targeting</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Link Building</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>CPA Marketing</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Report Analysis</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center fondomodulo">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/modulos1.webp" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h2>Módulo Marketing:</h2> 
                    <h2 class="segundo">Generá leads y leads calificados para tu equipo comercial.</h2>
                    <ul>
                        <li>Segmentá contactos y realizá acciones dirigidas.</li>
                        <li>Promové la cartera inactiva de tus cuentas.</li>
                        <li>Identificá intereses y oportunidades de cross selling (productos complementarios).</li>
                        <li>Obtené registro de cada acción y conversación, detectando comportamientos de tus clientes ágilmente para la toma de decisiones.</li>
                        <li>Optimizá esfuerzos y costos a través de conversaciones automatizadas en WhatsApp.</li>
                    </ul>
                    <a href="#demo" class="btn btn-primary">Conoce más</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center fondomodulo">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/modulos2.webp" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h2>Módulo Pedidos:</h2> 
                    <h2 class="segundo">Digitalizá tu catálogo para que los clientes accedan de forma simple y segura.</h2>
                    <ul>
                        <li>Tomá pedidos a partir de leads calificados generados con tus campañas en el módulo marketing Agromodo.</li>
                        <li>Recepcioná órdenes de pedido de manera automática 24x7.</li>
                        <li>Disponibilizá tu catálogo de productos de manera simple vía WhatsApp.</li>
                        <li>Conectá Agromodo con tus acciones en redes sociales para generar conversión.</li>
                        <li>Liberá tiempo evitando tareas repetitivas.</li>
                    </ul>
                    <a href="#demo" class="btn btn-primary">Conoce más</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center fondomodulo">

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/modulos3.webp" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h2>Módulo Atención a clientes:</h2> 
                    <h2 class="segundo">Mejorá la experiencia de contacto con los clientes</h2>
                    <ul>
                        <li>Liberá a tu equipo comercial de tareas repetitivas permitiendo enfocarse en generar nuevas oportunidades de negocio.</li>
                        <li>Intervení manualmente cuando la conversación lo requiere.</li>
                        <li>Respondé 24x7 de manera automática a consultas o reclamos de tus usuarios.</li>
                        <li>Gestioná múltiples operadores</li>
                        <li>Optimizá tiempos de respuesta.</li>
                        <li>Generá fidelización permanente con tus clientes.</li>
                    </ul>
                    <a href="#demo" class="btn btn-primary">Conoce más</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>

</section>
<!-- End Overview Area -->

<!-- Start Join Area -->
<!-- <section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="assets/img/3.png" alt="img">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Overall 400k+ Our Clients! Please Get Start Now</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Join Area -->

<!-- Start Analysis Area -->
<!-- <section class="analysis-area ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="analysis-form">
                    <h3>Free SEO Analysis</h3>

                    <form>
                        <div class="form-group">
                            <input type="text" placeholder="Name*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="email" placeholder="Email*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="text" placeholder="Phone*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="text" placeholder="Website*" class="form-control">
                        </div>

                        <div class="form-group">
                            <input type="text" placeholder="Company Name*" class="form-control">
                        </div>

                        <button type="submit" class="btn btn-primary">Get Free Report</button>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="analysis-text">
                    <span>Free SEO Analysis</span>
                    <h3>Be on the Top & Get More Traffic to Your Website</h3>
                    <p>The following services explain how we approach SEO for a range of common purposes:</p>
                    <ul>
                        <li>If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                        <li>For businesses which sell products online and and improve their product listings in the search results.</li>
                        <li>If you’re investing in a new website it’s important to ensure it’s built to succeed in the search results too.</li>
                        <li>If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Analysis Area -->

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Con AGROMODO:</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono9.png" alt="image">
                    </div>
                    <p>Generá negocios en el canal donde ya está el productor. Simple y disponible 24x7.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono10.png" alt="image">
                    </div>
                    <p>Todos los contactos de la empresa segmentados en un solo lugar.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono11.png" alt="image">
                    </div>
                    <p>Historial y seguimiento en tiempo real de cada conversación.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <img class="iconofoto" src="assets/img/icono12.png" alt="image">
                    </div>
                    <p>Liberá tiempos y potenciá la gestión de tu equipo comercial.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Project Area -->
<!-- <section class="project-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Recent Work</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>

    <div class="project-slides owl-carousel owl-theme">
        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img1.jpg" alt="work">
                <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img2.jpg" alt="work">
                <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img3.jpg" alt="work">
                <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img4.jpg" alt="work">
                <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img5.jpg" alt="work">
                <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img6.jpg" alt="work">
                <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section> -->
<!-- End Project Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member-box">
                <img src="assets/img/team-img1.jpg" alt="team">

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img2.jpg" alt="team">

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img3.jpg" alt="team">

                <div class="team-content">
                    <h3>David Luiz</h3>
                    <span>Designer</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img4.jpg" alt="team">

                <div class="team-content">
                    <h3>Park Tim</h3>
                    <span>SEO Expert</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img5.jpg" alt="team">

                <div class="team-content">
                    <h3>Doglas Costa</h3>
                    <span>Manager</span>
                    <ul>
                        <li><a href="#" target="_blank" class="facebook"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank" class="instagram"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank" class="twitter"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100" id="planes">
    <div class="container">

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Módulo MARKETING:</h3>
                        <h4 class="title">Planes</h4>
                        <h4 class="title last">Standard / Avanzado / Premium</h4>
                    </div>

                    <h5 class="first">Funcionalidades</h5>
                    <h5>(Según Plan)</h5>
                    <ul class="pricing-content">
                        <li>Hasta 9000 Conversaciones / mes</li>
                        <li>Línea WhatsApp con tu marca</li>
                        <li>Administrar Contactos : Cargar / Segmentar / Crear Listas</li>
                        <li>Crear Campañas</li>
                        <li>Visualizar campañas Activas</li>
                        <li>Gestión de leads</li>
                        <li>Gestión de leads calificados</li>
                        <li>Generar plantillas con variables</li>
                        <li>Panel de métricas y gestión online</li>
                        <li>Generar nuevas listas según contactos en etapas del Funnel.</li>
                        <li>Reuniones de mejora y Soporte</li>
                    </ul>

                    <a href="#demo" class="btn btn-primary">Quiero probarlo</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header dark">
                        <h3 class="title">Módulo PEDIDOS:</h3>
                        <h4 class="title">Planes</h4>
                        <h4 class="title last">Standard / Avanzado / Premium</h4>
                    </div>

                    <h5 class="first">Funcionalidades</h5>
                    <h5>(Según Plan)</h5>
                    <ul class="pricing-content">
                        <li>Hasta 9000 Conversaciones / mes</li>
                        <li>Múltiples operadores</li>
                        <li>Línea WhatsApp con tu marca</li>
                        <li>Administrar catálogo de productos (Carga Manual / Carga Masiva)</li>
                        <li>Actualización automatizada del catálogo digital en el WhatsApp</li>
                        <li>Panel Gestión de pedidos (búsquedas - reportes - estado)</li>
                        <li>Métricas online</li>
                        <li>Reporte de consultas a productos y reporte de clientes</li>
                        <li>Reuniones de mejora y Soporte</li>
                    </ul>

                    <a href="#demo" class="btn btn-primary">Quiero probarlo</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Módulo Atención al cliente:</h3>
                        <h4 class="title">Planes</h4>
                        <h4 class="title last">Standard / Avanzado / Premium</h4>
                    </div>

                    <h5 class="first">Funcionalidades</h5>
                    <h5>(Según Plan)</h5>
                    <ul class="pricing-content">
                        <li>Hasta 9000 Conversaciones / mes</li>
                        <li>Múltiples operadores</li>
                        <li>Línea WhatsApp Exclusiva con tu marca</li>
                        <li>Visualizar múltiples conversaciones en simultaneo y en tiempo real</li>
                        <li>Intervención manual de un operador</li>
                        <li>Auto-asignación de conversaciones</li>
                        <li>Derivar conversaciones entre operadores</li>
                        <li>Sistema de filtros por estadíos de consultas</li>
                        <li>Dashboard online (Métricas)</li>
                        <li>Requerir ser atendido por un operador</li>
                        <li>Balance de conversaciones entre operadores</li>
                        <li>Consultas pre categorizadas</li>
                        <li>Segmentación de consultas por operador</li>
                        <li>Reuniones de mejora y Soporte</li>
                    </ul>

                    <a href="#demo" class="btn btn-primary">Quiero probarlo</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2019</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                    </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<!-- <section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section> -->
<!-- End Feedback Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100 bg-gray" id="demo">
    <div class="container">
        <div class="section-title">
            <p>Potencia tu negocio <b>ahora</b></p>
            <h2>Coordinemos una demo</h2>
            <div class="bar"></div>
        </div>

        <div class="get-started-form">
            <form (ngSubmit)="sendForm()" [formGroup]="formContacto">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="contact" placeholder="Mail o número de teléfono">
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" formControlName="name" placeholder="Nombre y Apellido">
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa">
                </div>
                
                <button type="submit" class="btn btn-primary">Enviar</button>
            </form>
        </div>
        

        <div class="section-title">
            <p><b>Contacto: +54 341 2828012</b></p>
            <p><b>giuliano@alternativasinteligentes.com</b></p>
        </div>

        <div class="divalerta" [hidden]="!send">
            <div class="alert" class="alerta" role="alert">
                ¡Gracias! Recibimos tus datos y nos comunicaremos pronto.
            </div>
        </div>
        <div class="divalerta" [hidden]="!error">
            <div class="alert" class="alerta" role="alert">
                No se ha podido enviar sus datos. Intente de nuevo.
            </div>
        </div>
        <div class="divalerta" [hidden]="!empty">
            <div class="alert" class="alerta" role="alert">
                ¡Atención! Debes completar el formulario.
            </div>
        </div>
    </div>
    <div class="shape12"><img src="assets/img/sombra.png" alt="img"></div>
</section>
<!-- End Get Started Area -->

<section class="final ptb-100" id="quienes">
    <div class="container">
        <div class="seccion-final">
            <h2>Desde el Agro</h2>
            <p>
                Contamos con +7 años de experiencia brindando soluciones para digitalizar los procesos logísticos y comerciales de la agroindustria.
                <br>
                <br>
                Entre otras soluciones creamos MUVIN APP,<br> la plataforma de logística de la 
                Bolsa de Comercio de Rosario que utilizan productores, agronomías, acopios, transportes, corredores y agroexportadores.
                <br>
                <br>
                Entendemos y relevamos las necesidades de productores y empresas en cada eslabón de la cadena. 
                <br>
                <br>
                <b>Escuchamos a nuestros clientes. <br>
                    Interpretamos juntos las necesidades del productor. <br> Asi nació Agromodo.</b></p>

                <div class="imgfinal">
                    <img style="width: 60%;" src="assets/img/aisablanco.webp" alt="image">
                </div>
        </div>
    </div>
</section>

<!-- Start Platform Connect -->
<!-- <section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">
                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/slack.png" alt="mailchimp">
                    <h3>Slack</h3>
                    <span>Messaging</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/twitter.png" alt="mailchimp">
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/instagram.png" alt="mailchimp">
                    <h3>Instagram</h3>
                    <span>News Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section> -->
<!-- End Platform Connect -->
