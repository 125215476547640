<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav marketing-navbar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand back-to-top2" >
                    <img class="logoW" src="assets/img/agromodo.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ms-auto">

                        <li><a href="#agromodo" routerLinkActive="active">Por qué Agromodo</a></li>
                        <li><a href="#modulos" routerLinkActive="active">Módulos</a></li>
                        <li><a href="#planes" routerLinkActive="active">Planes</a></li>
                        <li><a href="#demo" routerLinkActive="active">Solicita una demo</a></li>
                        <li><a href="#quienes" routerLinkActive="active">Quiénes somos</a></li>

                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>