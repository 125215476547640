<!-- Start Coming Soon Area -->
<section class="coming-soon">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <h1>We are launching our new website</h1>
                    <p>Working hard for something we don't care about is called stress. Working hard for smething we love is called passion.</p>

                    <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div>

                    <form>
                        <input type="text" class="form-control" placeholder="Type your email address" required>
                        <button type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Coming Soon Area -->